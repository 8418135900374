import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { LookupResult } from '../../../openapi';
import { Constants } from '../../../../../shared/src/lib/constants';

@Component({
  selector: 'app-reliability-icon',
  standalone: true,
  imports: [SvgIconComponent],
  template: `
  <svg-icon [src]="selectedIcon" [svgStyle]="{'max-height':'24px', 'max-width':'24px',fill:selectedColor}" />
  `
})
export class ReliabilityIconComponent implements OnInit {

  @Input() reliabilityLookup: LookupResult;

  protected selectedIcon: string;
  protected selectedColor: string;

  ngOnInit(): void {
    if (!this.reliabilityLookup) {
      this.selectedIcon = '';
      return;
    }

    let selectedIcon;
    switch (this.reliabilityLookup.id) {
      case Constants.TeamMemberReliability.High:
        selectedIcon = 'shield-diamond';
        this.selectedColor = 'var(--bs-secondary)';
        break;
      case Constants.TeamMemberReliability.Medium:
        selectedIcon = 'shield-check';
        this.selectedColor = 'var(--bs-success)';
        break;
      default:
        selectedIcon = 'shield-x';
        this.selectedColor = 'var(--bs-danger)';
        break;
    }

    this.selectedIcon = `assets/images/icons/${selectedIcon}.svg`;
  }

}
