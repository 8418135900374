import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  public getAuthCookie(): string | null {
    return this.getCookie('authCookie');
  }

  setAuthCookie(authCookie: string): void {
    this.setCookie('authCookie', authCookie);
  }

  clearAuthCookie(): void {
    this.deleteCookie('authCookie');
  }

  // Helper function to get a cookie by name
  private getCookie(name: string): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
  }

  // Helper function to set a cookie
  private setCookie(name: string, value: string): void {
    document.cookie = `${name}=${value};path=/`;
  }

  // Helper function to delete a cookie by name
  private deleteCookie(name: string): void {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  }
}
