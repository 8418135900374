import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from './cookie.service';

@Injectable()
export class CookieInterceptor implements HttpInterceptor {


  constructor(private _cookieService: CookieService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the cookie value from your storage, replace 'cookieValue' with your actual cookie value
    const authCookie = this._cookieService.getAuthCookie();
    if (authCookie) {
      // Clone the request and add the cookie to the headers
      const modifiedRequest = request.clone({
        withCredentials: true,
        headers: request.headers.set('Cookie', authCookie)
      });

      // Pass the modified request on to the next handler
      return next.handle(modifiedRequest);
    }

    return next.handle(request);
  }
}
