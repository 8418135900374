import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpInterceptorFn, } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { AuthService } from '../../pages/auth/auth.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(AuthService.accessTokenKey)
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem(AuthService.accessTokenKey)}`,
        },
      })
    }
    return next.handle(request)
  }
}

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const token = localStorage.getItem(AuthService.accessTokenKey)
  if (token) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem(AuthService.accessTokenKey)}`,
      },
    })
  }
  return next(req)
};
