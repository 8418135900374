import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './../../pages/auth/auth.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { inject } from '@angular/core';
import { BlockUIService } from 'ng-block-ui';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  private readonly lang!: 'ar' | 'en';

  constructor(private toastrService: ToastrService, private AuthService: AuthService, private router: Router) {

    this.lang = localStorage.getItem('lang') == 'ar' ? 'ar' : 'en';

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'Accept-Language': localStorage.getItem('lang') === 'ar' ? `ar-SA` : 'en-US',
      },
    })
    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        let errorMessage = '';

        if (errorResponse.status === 401 || errorResponse.status === 0) {
          this.AuthService.signOut();
          return throwError(() => new Error(errorMessage));
        } else if (errorResponse.status === 402) {
          this.router.navigate(['/subscription-management'], { state: { validateExpiredSubscription: true } });
          return throwError(() => new Error(errorMessage));
        } else if (errorResponse.status === 500) {
          const message = this.lang == 'ar' ? 'حدث خطأ في النظام، يرجى المحاولة في وقت لاحق' : 'System error, Please try again later';
          this.toastrService.error(message, undefined, {
            positionClass: 'toast-top-center',
            closeButton: false,
            enableHtml: true,
            timeOut: 5000,
            disableTimeOut: 'extendedTimeOut'
          });
          return throwError(() => new Error(errorMessage));
        }

        if (errorResponse.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${errorResponse.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${errorResponse.status}\nMessage: ${errorResponse.error}`;
        }
        this.toastrService.clear();

        this.toastrService.error(errorResponse.error?.errors[0], undefined, {
          positionClass: 'toast-top-center',
          closeButton: false,
          enableHtml: true,
          timeOut: 5000,
          disableTimeOut: 'extendedTimeOut'
        });
        return throwError(() => new Error(errorMessage));
      })
    );
  }
}


interface MessageMapper {
  [key: string]: string
}
const message: MessageMapper = {
  'UserExists': 'لديك حساب مسجل بالفعل',
  'PasswordRequiresNonAlphanumeric': 'كلمة المرور يجب ان تحتوي على كلمات وحروف'
}

export function mapErrorMessage(code: string): string {
  const mappedMessage = message[code];
  return mappedMessage ?? 'حدث خطأ'
}


export const errorInterceptor: HttpInterceptorFn = (request, next) => {
  const injector = inject(Injector)
  const toastrService = injector.get(ToastrService);
  const router = injector.get(Router);
  const lang = localStorage.getItem('lang') == 'ar' ? 'ar' : 'en';

  request = request.clone({
    setHeaders: {
      'Accept-Language': localStorage.getItem('lang') === 'ar' ? `ar-SA` : 'en-US',
    },
  })
  return next(request).pipe(
    catchError((errorResponse: HttpErrorResponse) => {
      let errorMessage = '';


      switch (errorResponse.status) {
        case 0:
          if (errorResponse.error instanceof ProgressEvent) {
            console.error('Connection refused error (ERR_CONNECTION_REFUSED):', errorResponse);
            const message = lang == 'ar' ? 'حدث خطأ في النظام، يرجى المحاولة في وقت لاحق' : 'System error, Please try again later';
            showErrorMessage(toastrService, message);
          }
          return throwError(() => new Error(errorMessage));
        case 401:
          localStorage.clear();
          router.navigate(['/auth/login']);
          return throwError(() => new Error(errorMessage));

        case 402:
          router.navigate(['/subscription-management'], { state: { validateExpiredSubscription: true } });
          showErrorMessage(toastrService, errorResponse.error.errorString);
          return throwError(() => new Error(errorMessage));

        case 500:
          const message = lang == 'ar' ? 'حدث خطأ في النظام، يرجى المحاولة في وقت لاحق' : 'System error, Please try again later';
          showErrorMessage(toastrService, message);
          return throwError(() => new Error(errorMessage));

        default:

          if (errorResponse.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${errorResponse.error.message}`;
          } else {
            // server-side error
            errorMessage = `Error Code: ${errorResponse.status}\nMessage: ${errorResponse.error}`;
          }
          toastrService.clear();
          console.error({ errorResponse })
          if (errorResponse.error) {
            showErrorMessage(toastrService, errorResponse.error.errors[0]);
          }
          return throwError(() => new Error(errorMessage));
      }
    }
    ));

};


function showErrorMessage(toastrService: ToastrService, message: string) {
  toastrService.error(message, undefined, {
    positionClass: 'toast-top-center',
    closeButton: false,
    enableHtml: true,
    timeOut: 5000,
    disableTimeOut: 'extendedTimeOut'
  });
}
